import { ContentfulNextJsImage } from '@components/common/ContentfulImage'
import { KeenSliderWrapper } from '@components/common/KeenSliderWrapper'
import { useDevice } from '@utils/responsiveness/useDevice'
import { useRouter } from 'next/router'
import { IConfigurableFeature } from '../ConfigurableFeatures/features.types'

export function FeatureCollectionInfiniteSlider({
  features,
  trackingIdentifier,
}: {
  features: IConfigurableFeature[]
  trackingIdentifier: string
}) {
  const router = useRouter()
  const { isMobile } = useDevice()
  const sliderBreakpoints = {
    '(min-width: 480px)': {
      slides: { perView: 2.5, spacing: 8, origin: 'center' as null },
    },
    '(min-width: 640px)': {
      slides: { perView: 3.2, spacing: 8, origin: 'center' as null },
    },
    '(min-width: 1280px)': {
      slides: { perView: 4.2, spacing: 8, origin: 'center' as null },
    },
  }

  const featureCollection =
    features.length < 6
      ? [...features, ...features, ...features, ...features, ...features] // ensure the slider doesn't have gaps in large screens when there are less items
      : features

  return (
    <div className={`relative flex justify-center w-full max-w-full`}>
      <KeenSliderWrapper
        initialConfig={{
          perView: 1.5,
          spacing: 8,
          origin: 'center',
        }}
        breakpoints={sliderBreakpoints}
        loop={true}
        isShowArrows={!isMobile}
        trackingIdentifier={trackingIdentifier}
      >
        {featureCollection.map((feature, index) => (
          <div
            className={`flex rounded-[20px] overflow-hidden keen-slider__slide ${
              feature.ctaLink && 'cursor-pointer'
            }`}
            key={feature.id + '_' + index}
            onClick={() => {
              if (feature.ctaLink) {
                router.push(feature.ctaLink).catch(() => {})
              }
            }}
          >
            <ContentfulNextJsImage
              src={feature.image?.imageUrl}
              alt={feature.image?.imageTitle}
              quality={80}
              width={600}
              height={450}
              objectFit="cover"
              format="jpg"
              className="transition-transform duration-300 hover:scale-105"
            />
          </div>
        ))}
      </KeenSliderWrapper>
    </div>
  )
}
